<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">住房管理</span>
    </div>
    <div class="operation">
      <el-button type="primary" @click="addClick">新增住房</el-button>
    </div>
		<div class="top-box">
      <div class="top-one">
				<span class="labelText" type="info">楼栋号：</span>
				<el-select class="rantHouseName" v-model="building_id"  @change="louDongChange" placeholder="请选择">
					<el-option v-for="item in louDongList" :key="item.building_number" :label="item.building_number" :value="item.id">
					</el-option>
				</el-select>
			</div>
      <div class="top-one">
				<span class="labelText" type="info">单元号：</span>
				<el-select class="rantHouseName" v-model="unit_id"  placeholder="请选择">
					<el-option v-for="item in danYuanList" :key="item.unit_number" :label="item.unit_number" :value="item.id">
					</el-option>
				</el-select>
			</div>
      <div class="top-one">
				<span class="labelText" type="info">房屋号：</span>
				<el-input class="rantHouseName" v-model="house_number"  placeholder="请输入"></el-input>
			</div>
      <div class="top-two">
        <el-button class="serach" type="primary" @click="search">搜索</el-button>
        <el-button class="serach" type="warning" @click="removeSearch">清空</el-button>
      </div>
		</div>
    <div class="cont_container">
      <template>
        <el-table class="table"  
        v-loading="loading" 
        :element-loading-text="loadingText" 
        element-loading-spinner="el-icon-loading"
        :element-loading-background="loadingColor" 
        :data="zhuFangList" 
        :header-cell-style="TableTitleBackColor"
        :row-style="tableCss" 
        :highlight-current-row="true" 
        :cell-style="cellStyle">
          <el-table-column label="序号" type="index" width="200">
          </el-table-column>
          <el-table-column prop="building_number" label="所属楼栋">
          </el-table-column>
          <el-table-column prop="unit_number" label="单元号">
          </el-table-column>
          <el-table-column prop="house_number" label="房屋号">
          </el-table-column>
          <el-table-column prop="unit_number" label="房屋人员">
            <template slot-scope="scope">
              <div style="color:#409EFF" @click="goTorenYuanInZhuFang(scope.row)" >查看</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button  type="primary" size="mini" @click="goToEditPage(scope.row)">编辑</el-button>
              <el-button  type="primary" size="mini" @click="deleteZhuFang(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
	import {
		mapState
	} from "vuex";
  import {getZhuFangListFn,deleteZhuFangFn} from '@/api/zhuFang.js'
  import {getLouDongListSelectFn,getDanYuanSelectFn} from '@/api/select.js'
	export default {
		data() {
			return {
				loading: false,
				page: 1,
				page_size: 10,
				last_page: 0,

        louDongList:[],
        building_id:'',

        danYuanList:[],
        unit_id:'',

        zhuFangList: [],
        house_number:''
			}
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
    created() {
      this.getLouDongList()
    },
    mounted() {
			this.getZhuFangList();
		},
		methods: {
      //删除
      deleteZhuFang(row){
        let params = {
          api_token: localStorage.getItem('token1'),
          id:row.id
        }
        this.$confirm('确定删除', '确定删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          deleteZhuFangFn(params).then(res => {
            this.getZhuFangList()
          })
        });
      },
      //去房屋下人员列表
      goTorenYuanInZhuFang(row){
        this.$router.push({
					path: '/renYuanListInZhuFang',
					query: {
						id: row.id,
					}
				});
      },
      //清空搜索
			removeSearch(){
				this.building_id = '';
        this.unit_id = '';
        this.house_number = '';
        this.danYuanList = []
			},
      //搜索
      search() {
				this.page = 1;
				this.getZhuFangList();
			},
      //楼栋改变
      louDongChange(value){
        this.unit_id = ''
        this.getDanYuanList(value)
      },
      //获取楼栋列表
      getLouDongList(id) {
				getLouDongListSelectFn({
          api_token: localStorage.getItem('token1'),
				}).then(res => {
					this.louDongList = res.datas;
				})
			},
      //获取单元列表
      getDanYuanList(id) {
				getDanYuanSelectFn({
          api_token: localStorage.getItem('token1'),
          building_id:id
				}).then(res => {
					this.danYuanList = res.datas;
				})
			},
      //获取住房列表
			getZhuFangList() {
				this.loading = true;
				getZhuFangListFn({
					api_token : localStorage.getItem('token1'),
					page: this.page,
					page_size: this.page_size,
          village_id:this.village_id,
          building_id:this.building_id,
          unit_id:this.unit_id,
          house_number:this.house_number
				}).then(res => {
					this.zhuFangList = res.datas.data;
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
			//新增
			addClick() {
				this.$router.push({
					path: '/addZhuFang'
				});
			},
			//编辑
			goToEditPage(row) {
				this.$router.push({
					path: '/editZhuFang',
					query: {
						id: row.id,
						page: this.page,
						last_page: this.last_page,
					}
				});
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getZhuFangList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped>
	@import url("../../../style/title.css");
</style>
